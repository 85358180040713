import qs from 'qs'
import stairsMixins from './stairs'

export default {
  mixins: [stairsMixins],
  methods: {
    buildIntervalsFilters(item) {
      return Object.assign({}, this.module.getters.structuredQuery.filters, this.getRecursiveParams(item))
    },
    getIntervalLink(item, entity) {
      entity = entity || (item.entity && item.entity.alias)

      if (entity && ['client', 'employee', 'project', 'task'].indexOf(entity) + 1) {
        let filters = this.buildIntervalsFilters(item)
        return (
          '/reports/intervals?' +
          qs.stringify({
            filters: {
              intervals: filters,
            },
          })
        )
      } else return null
    },
  },
}
