<template>
  <b-tooltip :target="id + '_' + user.id" boundary="viewport">
    <b>{{ user.name }}</b>
    <div class="text-left mt-2">
      Часы: {{ user.seconds | formatDuration('hh[ч] mm[м]') }}<br />
      Себестоимость: {{ user.worth | formatNumber }} &#8381;<br />
      Стоимость: {{ user.cost | formatNumber }} &#8381;<br />
      Маржа: {{ user.margin | formatNumber }} &#8381;
    </div>
  </b-tooltip>
</template>

<script>
  export default {
    name: 'user-tooltip',
    props: ['user', 'id'],
  }
</script>

<style scoped></style>
