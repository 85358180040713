<template>
  <div v-if="usersArray && usersArray.length" class="users" @click.prevent.stop>
    <template v-if="index < limit" v-for="(user, index) in usersArray">
      <img :id="id + '_' + user.id" class="avatar" :src="user.photo" />
      <user-tooltip :id="id" :user="user"></user-tooltip>
    </template>
    <template v-if="usersArray.length > limit">
      <div style="display: inline" :id="'more-followers-' + id">
        <fa class="ml-1" icon="angle-right" size="sm"></fa>
      </div>
      <popover ref="popover" :target="'more-followers-' + id" placement="right">
        <div class="project-followers">
          <template v-for="(user, index) in usersArray" v-if="index >= limit">
            <span>
              <img
                :id="id + '_' + user.id"
                class="avatar"
                :src="user.photo"
                :title="user.name"
                @error="onError($event)"
              />{{ user.name }}
              <user-tooltip :id="id" :user="user"></user-tooltip>
            </span>
          </template>
        </div>
      </popover>
    </template>
  </div>
</template>

<script>
  import defaultAvatar from '../../../../static/img/avatars/default.jpg'
  import UserTooltip from './UserTooltip'

  import Popover from '../../../components/PopoverAlive'

  export default {
    components: { Popover, UserTooltip },
    name: 'users',
    props: {
      users: null,
    },
    data() {
      return {
        id: this.getUniqId(),
        limit: 10,
      }
    },
    computed: {
      usersArray() {
        return Object.values(this.users)
      },
    },
    methods: {
      onError(e) {
        e.target.src = defaultAvatar
      },
    },
  }
</script>

<style lang="scss" scoped>
  .users {
    margin-left: auto;
  }

  .avatar {
    width: 20px;
    margin-left: 5px;
    border-radius: 50%;
  }

  .popover {
    .project-followers {
      display: grid;

      & > span {
        margin-bottom: 5px;

        img {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
</style>
