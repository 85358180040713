<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-card>
        <b-table
          :fields="table.fields"
          :items="rows"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          :filter="filter"
          no-local-sorting
          responsive
        >
          <template slot="name" slot-scope="data">
            <expander
              :level="data.item.level"
              :has-items="hasItems(data.item)"
              :expanded="data.item._expanded"
              @expand="expand(data.item)"
            >
              <template slot="text">
                <span style="margin-right: 15px">
                  <template v-if="data.item.entity && positions.indexOf('admin') >= 0">
                    <fa
                      icon="pencil-alt"
                      size="sm"
                      class="mx-10 edit-icon"
                      @click.prevent.stop="modalSwitch(data.item, 'edit-' + data.item.entity.alias + 's')"
                    />
                  </template>
                  <span @click="expand(data.item)"
                    >{{ data.value || 'Без названия' | slicer(50) }}
                    <span v-if="data.item.entity" style="opacity: 0.7"
                      >({{ data.item.entity.name.toLowerCase() }})</span
                    >
                  </span>
                  <span v-if="data.item['_links']" v-for="(link, alias) in data.item['_links']">
                    | <a :href="link.link" target="_blank">{{ link.name }}</a>
                  </span>
                </span>
                <users v-if="data.item.users" :users="data.item.users"></users>
              </template>
            </expander>
          </template>

          <template slot="seconds" slot-scope="data">
            <component
              :is="getIntervalLink(data.item) ? 'b-link' : 'span'"
              :to="getIntervalLink(data.item)"
              target="_blank"
            >
              {{ data.value | formatDuration }}
            </component>
          </template>

          <template slot="rate" slot-scope="data"> {{ data.value | formatNumber }} &#8381; </template>

          <template slot="worth" slot-scope="data"> {{ data.value | formatNumber }} &#8381; </template>

          <template slot="is_premium" slot-scope="{ value }">
            <b-badge v-if="typeof value === 'boolean'" :variant="value ? 'success' : 'secondary'">
              {{ value ? 'Премиум' : 'Не премиум' }}
            </b-badge>
          </template>
        </b-table>
        <component v-if="modal.id" :is="modal.component" :source="modal.item" :module="module"></component>
      </b-card>
    </template>
  </list>
</template>

<script>
  import { getUsersDropdown } from '../../../assets/js/common'
  import { mapState } from 'vuex'

  import List from '../../../components/List'
  import Expander from '../../../components/Expander'

  import Users from './Users'
  import EditProjects from '../../../views/Projects/EditProject'
  import EditTasks from '../../../views/Tasks/EditTask'
  import ProgressBar from '../../../components/ProgressBar'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'
  import intervalsMixins from '../../../mixins/intervals'

  export default {
    name: 'report',
    components: {
      ProgressBar,
      Expander,
      Users,
      List,
      EditProjects,
      EditTasks,
    },
    mixins: [entityMixins, stairsMixins, intervalsMixins],
    data() {
      return {
        table: {
          fields: [
            {
              key: 'name',
              label: 'Название',
            },
            {
              key: 'is_premium',
              label: 'Премиальнось',
              class: 'text-center',
            },
            {
              key: 'seconds',
              label: 'Время',
              class: 'text-center',
            },
            {
              key: 'rate',
              label: 'Оклад',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'worth',
              label: 'Себестоимость',
              class: 'text-center',
              sortable: true,
              roles: ['admin'],
            },
          ],
        },
        filters: [
          {
            name: 'project',
            label: 'Проект',
            width: 2,
          },
          {
            name: 'client',
            label: 'Клиент',
            width: 2,
          },
          {
            name: 'date',
            type: 'date',
            range: true,
          },
          {
            id: 'responsible',
            name: 'users',
            label: 'Ответственный',
            type: 'select',
            multiple: true,
            width: 2,
            roles: ['manager'],
          },
          {
            name: 'visibility',
            label: 'Тип',
            type: 'select',
            items: [
              {
                name: 'Внутренние',
                value: 'inside',
              },
              {
                name: 'Внутренние премиальные',
                value: 'inside_premium',
              },
              {
                name: 'Внутренние непремиальные',
                value: 'inside_not_premium',
              },
              {
                name: 'Внешние',
                value: 'outside',
              },
              {
                name: 'Все',
                value: 'all',
              },
            ],
            width: 2,
          },
        ],
      }
    },
    computed: {
      ...mapState({
        positions: state => state.auth.user.positions,
      }),
    },
    methods: {
      resultHandler(result) {
        result.items.forEach(item => {
          item._expanded = true
        })

        this.dataPrepare(result)
      },
    },
    beforeRouteEnter(to, from, next) {
      getUsersDropdown().then(items => {
        next(vm => {
          const found = vm.filters.find(filter => filter.name === 'users')
          if (found) found.items = items
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    #responsible {
      .dropdown-item {
        white-space: pre-wrap;
      }
    }
    .b-table {
      th:first-child {
        /*width: 40%;*/
      }
    }
    .progress-bar {
      transition: unset !important;
    }
  }
</style>
